import { WppCard, WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormRichText } from 'components/form/formRichText/FormRichText'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import styles from 'pages/processBuilder/editProcessModal/EditProcessModal.module.scss'
import { templateConfigs } from 'pages/processBuilder/templates'
import { getIsFieldHidden } from 'pages/processBuilder/utils'
import { Templates } from 'types/process/process'

export const MAX_DESCRIPTION_CHARACTERS = 230

interface Props {
  isEditMode: boolean
}

export const PhaseZeroFields = ({ isEditMode }: Props) => {
  const { register, control, watch } = useFormContext()
  const formData = watch()
  const { t } = useTranslation()
  const isFieldHidden = getIsFieldHidden(formData.template as Templates)
  const colorsConfig = templateConfigs[formData.template as Templates].colors.phaseZero

  return (
    <WppCard>
      <WppAccordion withDivider={false} className={styles.phaseAccordion}>
        <WppTypography type="s-strong" slot="header">
          {t('process.edit_modal.phase_number', { number: 0 })}
        </WppTypography>

        <Flex direction="column" gap={10} className={styles.phaseFieldsWrap}>
          <Controller
            render={({ field }) => (
              <FormTextareaInput
                {...field}
                placeholder={t('process.edit_modal.title')}
                hidden={isFieldHidden('phases', 'title')}
                rows={2}
              />
            )}
            name="phaseZero.title"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <FormRichText
                {...field}
                placeholder={t('process.edit_modal.description')}
                hidden={isFieldHidden('phases', 'description')}
                charactersLimit={MAX_DESCRIPTION_CHARACTERS}
                warningThreshold={MAX_DESCRIPTION_CHARACTERS}
              />
            )}
            name="phaseZero.description"
            control={control}
          />

          <Flex gap={8}>
            <FormPalette
              useDefaultColors={!isEditMode}
              {...register('phaseZero.colors')}
              title={t('common.colors')}
              colorsConfig={colorsConfig}
              hidden={isFieldHidden('phases', 'colors')}
              className={styles.colorsInput}
              disableExpanding
            />
          </Flex>

          <Flex direction="row" justify="between" align="center" gap={15} wrap="wrap">
            <FormCheckbox
              {...register('phaseZero.innerPageEnabled')}
              labelConfig={{
                text: t('process.edit_modal.enable_inner_page'),
              }}
              className={styles.inputHalfRow}
              hidden={isFieldHidden('phases', 'innerPageEnabled')}
            />
          </Flex>
        </Flex>
      </WppAccordion>
    </WppCard>
  )
}
